import React from "react"
import { Box } from "./Grid"
import { Text } from "./Text"
import { theme } from "../util/style/theme"
import styled from "styled-components"

const Link = styled("a")`
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    text-decoration: none;
  }
`

const Presence = () => {
  return (
    <Box maxWidth="940px" m="0 auto" px={3}>
      <Box mt={[5, "124px"]} mb={[5, "132px"]}>
        <Text as="h1" fontSize={["2.5rem", "3rem"]} mb={4} textAlign="center">
          Kehadiranmu adalah sebuah hadiah
        </Text>
        <Text
          as="p"
          style={{ opacity: 0.8 }}
          fontSize={["1rem", "1.4rem"]}
          textAlign="center"
        >
          Memiliki keluarga dan teman yang penuh kasih dalam hidup kita adalah
          hadiah terbaik dari semuanya. Jika kamu ingin memberikan kami dengan
          hadiah pernikahan, kami telah mengumpulkan{" "}
          <Link
            target="blank"
            href="https://docs.google.com/spreadsheets/d/1daOnackvUhdxWV0WcT6dYDAR6Lvvz_g1EkQfFK-mh0U/edit#gid=1386834576"
          >
            beberapa ide
          </Link>
          . Jangan lupa hubungi kami apabila sudah memilih hadiah.
        </Text>
      </Box>
    </Box>
  )
}

export default Presence
