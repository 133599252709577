import React from "react"
import PropTypes from "prop-types"
import { Box } from "../Grid"
import { OutboundLink } from "../Link"
import GlobalStyles from "../../util/style/GlobalStyles"
import { ThemeProvider, theme } from "../../util/style"

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyles />

      {children}
    </>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Layout }
