import React from "react"
import { Box } from "./Grid"
import { Text } from "./Text"
import { theme } from "../util/style/theme"
import styled from "styled-components"
import { Link } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "../util/style/react-tabs.css"

const Title = styled(Text)`
  font-size: 2.8rem;
  font-weight: 300;
  text-align: right;
`

const Description = styled(Text)`
  margin-left: ${theme.space[4]};
  margin-right: ${theme.space[4]};
  text-align: left;
  font-size: 1.3rem;
`

const Button = styled("a")`
  margin-left: ${theme.space[4]};
  margin-right: ${theme.space[4]};
  margin-top: ${theme.space[2]};
  background: rgba(20, 21, 26, 0.05);
  border-radius: ${theme.radii[2]};
  text-decoration: none;
  font-size: 0.8rem;
  letter-spacing: 0.7px;
  font-weight: 600;
  color: ${theme.colors.black} !important;
  padding: 18px 20px;
  text-transform: uppercase;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: rgba(20, 21, 26, 0.1);
    transition: all 0.2s ease-in-out;
  }
`

const Step = styled("ol")`
  margin-top: ${theme.space[3]};
  margin-bottom: 0;
`

const LinkA = styled("a")`
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    text-decoration: none;
  }
`

const Acara = () => {
  return (
    <Box maxWidth="940px" m="0 auto" px={3} id="acara">
      <Box mt={[5, "92px"]}>
        {/* Acara Pernikahan */}
        <Box>
          <Text as="p" mb={3} style={{ opacity: 0.8 }} textAlign="center">
            Gabung bersama kami
          </Text>
          <Text
            as="h1"
            mb={[3, 4]}
            fontSize={["2.5rem", "3rem"]}
            textAlign="center"
          >
            Acara Pernikahan
          </Text>
          <Text
            as="p"
            style={{ opacity: 0.8 }}
            fontSize={["1rem", "1.4rem"]}
            textAlign="center"
          >
            Untuk memulai perjalanan ini–layaknya peluit tanda memulai, aku
            ingin kamu bergabung untuk merayakan kebahagiaan bersama kami.
          </Text>

          <Box mt={[4, 5]} css="text-align: center">
            <div
              css="
            display: inline-grid; 
            grid-template-columns: 1fr 1.5fr; 
            grid-row-gap: 36px;
            grid-column-gap: 32px;
            align-items: center;

            @media only screen and (max-width: 576px) {
              grid-column-gap: 12px;
              grid-row-gap: 24px;

              .addtocalendar {
                display: none;
              } 

              h3 {
                font-size: 2rem;
              }

              p {
                font-size: 1rem;
              }

            }
          "
            >
              <div>
                <Title as="h3">Resepsi</Title>
              </div>
              <div>
                <Text
                  style={{
                    marginLeft: theme.space[4],
                    marginRight: theme.space[4],
                    textAlign: "left",
                    fontSize: "1.3rem",
                  }}
                ></Text>
                <Description>11.00 - 15.00</Description>
                <Description style={{ opacity: 0.6 }}>
                  Segera diumumkan
                </Description>
              </div>
              <div css="align-self: start">
                <Title as="h3">Lokasi</Title>
              </div>
              <div css="text-align: left">
                <Description
                  style={{
                    fontWeight: "600",
                  }}
                >
                  Gedung Graha Faletehan
                </Description>

                <Description>
                  Jl. Raya Cilegon KM. 06, Serang, Banten
                </Description>
                <Button target="blank" href="https://bit.ly/denahlokasi-ga">
                  Google Maps
                </Button>
              </div>
            </div>
          </Box>
        </Box>

        {/* Bagaimana saya bisa ke lokasi? */}
        <Box mt={[5, "92px"]}>
          <Text
            as="h1"
            fontSize={["2.5rem", "3rem"]}
            mb={[3, 4]}
            textAlign="center"
          >
            Bagaimana aku bisa ke lokasi?
          </Text>
          <Text
            as="p"
            fontSize={["1rem", "1.4rem"]}
            style={{ opacity: 0.8 }}
            textAlign="center"
          >
            Jika kamu bingung untuk lokasi acara, berikut contekan kecil
            untukmu.
          </Text>

          <Box mt={["25px", "52px"]}>
            <Tabs>
              <TabList>
                <Tab>Kendaraan Pribadi</Tab>
                <Tab>Travel</Tab>
                <Tab>Kereta Api</Tab>
                <Tab>Pesawat</Tab>
              </TabList>

              <TabPanel>
                <Text style={{ opacity: 0.8 }}>
                  Apabila kamu akan menggunakan kendaran pribadi roda empat,
                  ikuti petunjuk ini ya!
                </Text>
                <Step>
                  <li>Pastikan sudah isi bensin.</li>
                  <li>Siapkan kartu e-toll ya.</li>
                  <li>
                    Kamu bisa masuk ke jalan tol Merak - Jakarta/Tangerang -
                    Merak menuju Serang.
                  </li>
                  <li>
                    Ikuti petunjuk jalan tol yang mengarah ke Serang/Merak,
                    nanti kamu keluar di gerbang tol Serang Barat ya!
                  </li>
                  <li>
                    Hanya 2.5 km sejak keluar dari gerbang tol Serang Barat,
                    kamu akan sampai di lokasi loh.
                  </li>
                  <li>
                    Lokasinya ada di sebelah kiri jalan dari arah kamu datang
                    ya. Cek aja janur kuning Gema & Afnizar.
                  </li>
                </Step>
              </TabPanel>
              <TabPanel>
                <Text>
                  Apabila kamu akan menggunakan kendaran umum travel, ikuti
                  petunjuk ini ya!
                  <Step>
                    <li>
                      Silakan telpon ke nomor (021) 52962255 atau bisa via{" "}
                      <LinkA href="http://www.xtrans.co.id/" target="blank">
                        website
                      </LinkA>{" "}
                      untuk pesan tiket travel Xtrans ke Serang/Cilegon.
                    </li>
                    <li>
                      Bingung mau pesan ke Serang atau Cilegon? Tenang aja,
                      lokasi acara enggak jauh kok dari pool travel Serang
                      maupun Cilegon.
                    </li>
                    <li>
                      Lokasi pool travelnya ada di Hotel Kartika Chandra
                      Semanggi ya.
                    </li>
                    <li>
                      Pastikan kamu sudah sampai di pool travel 30 menit sebelum
                      keberangkatan ya! Kalau enggak, kamu bisa langsung
                      ditinggal.
                    </li>
                    <li>
                      Sesampainya di pool travel Serang/Cilegon, kamu bisa
                      melanjutkan perjalanan menuju lokasi menggunakan
                      transportasi online apapun.
                    </li>
                    <li>
                      Kalau dari pool travel Serang ke lokasi, jaraknya sekitar
                      9.6 km. Sedangkan dari pool travel Cilegon sekitar 7.9 km
                      saja.
                    </li>
                    <li>
                      Kalau dari arah Serang, lokasinya ada di sebelah kiri
                      jalan dari arah kamu datang. Kalau dari arah Cilegon,
                      lokasinya ada di sebelah kanan jalan dari arah kamu datang
                      ya! Cek janur kuning Gema & Afnizar.
                    </li>
                  </Step>
                </Text>
              </TabPanel>
              <TabPanel>
                <Text>
                  Apabila kamu akan menggunakan commuter line dan kereta api,
                  ikuti petunjuk ini ya!
                </Text>
                <Step>
                  <li>Pastikan kamu punya kartu e-money ya.</li>
                  <li>Kamu bisa naik KRL jalur Tanah Abang - Rangkasbitung.</li>
                  <li>
                    Kamu turun di stasiun paling akhir, yaitu stasiun
                    Rangkasbitung.
                  </li>
                  <li>
                    Kemudian kamu akan transit di stasiun Rangkasbitung untuk
                    melanjutkan perjalanan menuju Serang menggunakan kereta api
                    ekonomi.
                  </li>
                  <li>
                    Beli tiketnya di stasiun Rangkasbitung ya! Oh ya, kereta
                    menuju Serang ada{" "}
                    <LinkA
                      href="http://keretaapikita.com/jadwal-ka-lokal-rangkasbitung-merak-terbaru/"
                      target="blank"
                    >
                      jadwalnya
                    </LinkA>
                    .
                  </li>
                  <li>
                    Sesampainya di Serang, kamu bisa menggunakan transportasi
                    online apapun di sana menuju lokasi acara.
                  </li>
                  <li>
                    Kalau dari arah Serang, lokasinya ada di sebelah kiri jalan
                    dari arah kamu datang ya! Cek janur kuning Gema & Afnizar.
                  </li>
                </Step>
              </TabPanel>
              <TabPanel>
                <Text>
                  Apabila kamu akan menggunakan pesawat, ikuti petunjuk ini ya!
                </Text>
                <Step>
                  <li>
                    Sesampainya di bandara mana pun, kamu bisa ikuti petunjuk
                    menggunakan transportasi travel atau pun kereta di tab
                    lainnya ya!
                  </li>
                  <li>
                    Kalau kamu dari bandara Soetta, kamu bisa menggunakan bus
                    damri menuju Serang.
                  </li>
                  <li>
                    Kemudian kamu bisa menggunakan transportasi online apapun
                    menuju lokasi acara.
                  </li>
                  <li>
                    Kalau dari arah Serang, lokasinya ada di sebelah kiri jalan
                    dari arah kamu datang. Kalau dari arah Cilegon, lokasinya
                    ada di sebelah kanan jalan dari arah kamu datang ya! Cek
                    janur kuning Gema & Afnizar.
                  </li>
                </Step>
              </TabPanel>
            </Tabs>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Acara
