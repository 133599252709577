import { createGlobalStyle } from "./index"
import theme from "./theme"
import "../../fonts/inter.css"
import "../../fonts/roslindale.css"

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html,
  body {
    min-height: 100%;
    min-width: 320px;
    padding: 0;
    margin: 0;
    background: ${({ theme }) => theme.colors.yellow[0]};
    color: ${({ theme }) => theme.colors.black};
    background-image: url(images/bg-top-left.png), url(images/bg-top-right.png), url(images/bg-center-left.png), url(images/bg-center-right.png);
    background-position: left 325px, right 0px, left 2050px, right 1600px;
    background-size: 557px auto, 532px auto, 356px auto, 452px auto;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  }

  @media only screen and (max-width: 768px) {
    body {
      background-position: left 200px, 500px -100px, left 2050px, right 1600px;
    }
  }

  @media only screen and (max-width: 992px) {
    body {
      background-image: url(images/bg-top-left.png);
      background-position: left 450px;
    }
  }

  @media only screen and (max-width: 576px) {
    body {
      background-image: url(images/bg-top-left.png) ;
      background-position: left 300px;
    }
  }

  @keyframes fadeInBottom {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
  
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes navFadeInTop {
    0% {
      transform: translateY(-90px);
      opacity: 0;
    }
  
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes wave {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(45deg);
    }
    50% {
      transform: rotate(0);
    }
    75% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(0);
    }
}
`

export default GlobalStyles
