import React from "react"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import Navigation from "../components/Navigation"
import Header from "../components/Header"
import CeritaKami from "../components/CeritaKami"
import Acara from "../components/Acara"
import Presence from "../components/Presence"
import Gallery from "../components/Gallery"
import Footer from "../components/Footer"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => {
  let invitationData = data.allSitePage.edges[0].node.context

  return (
    <Layout>
      <SEO />

      <Navigation name={invitationData.name} />
      <Header />
      <CeritaKami />
      <Acara />
      <Presence />
      <Gallery />
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageQuery($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            url
            name
          }
        }
      }
    }
  }
`

export default IndexPage
