import React from "react"
import { Box, Flex } from "./Grid"
import { Text } from "./Text"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { theme } from "../util/style/theme"
import Modal from "react-modal"

const Link = styled("a")`
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    text-decoration: none;
  }
`

const Step = styled("ol")`
  margin-top: ${theme.space[3]};
  margin-bottom: 0;
`

const Button = styled("div")`
  margin-top: ${theme.space[2]};
  background: rgba(20, 21, 26, 0.05);
  border-radius: ${theme.radii[2]};
  text-decoration: none;
  font-size: 0.8rem;
  letter-spacing: 0.7px;
  font-weight: 600;
  color: ${theme.colors.black} !important;
  padding: 18px 20px;
  text-transform: uppercase;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    background: rgba(20, 21, 26, 0.1);
    transition: all 0.2s ease-in-out;
  }
`

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      header: file(relativePath: { eq: "afnizar-gema-illustration.png" }) {
        childImageSharp {
          fluid(maxWidth: 322) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const [modalIsOpen, setIsOpen] = React.useState(false)
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <Box maxWidth="940px" m="0 auto" px={3}>
      <Box
        p={"16px"}
        mt={"80px"}
        style={{ backgroundColor: "#eee6dc", borderRadius: "4px" }}
      >
        <Flex>
          <img
            src="images/icon/ico-alert.svg"
            width="20"
            height="20"
            alt="Alert Icon"
            style={{ marginRight: "12px", top: "2px", position: "relative" }}
          />
          <Text>
            Mempertimbangkan situasi dan kondisi saat ini,{" "}
            <span style={{ fontWeight: "500" }}>
              resepsi sementara di undur
            </span>
            .{" "}
            <Link onClick={openModal} href="">
              Lebih lanjut
            </Link>
            .
          </Text>
        </Flex>
      </Box>
      <Box style={{ textAlign: "center" }} pt={"60px"}>
        <Text
          as="p"
          fontSize={["1rem", "1.4rem"]}
          mb={[3, 4]}
          css="animation: fadeInBottom 1.5s .8s cubic-bezier(0.19, 1, 0.22, 1) backwards;"
        >
          Undangan Pernikahan
        </Text>
        <Text
          as="h1"
          fontSize={["3rem", "4rem"]}
          mb={[3, 4]}
          css="animation: fadeInBottom 1.5s 1s cubic-bezier(0.19, 1, 0.22, 1) backwards;"
        >
          Gema & Afnizar
        </Text>
        <Img
          style={{ display: "block", margin: "0 auto" }}
          css="
            @media only screen and (max-width: 576px) {
              width: 80%;
            }
            @media only screen and (min-width: 576px) {
              width: 332px;
            }
          "
          fluid={data.header.childImageSharp.fluid}
          alt="Gema & Afnizar"
        />
      </Box>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            maxWidth: "748px",
            padding: "32px 32px 0px 32px",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          },
          overlay: {
            backgroundColor: "rgba(0,0,0, .2)",
            zIndex: 10,
          },
        }}
        contentLabel="Informasi"
      >
        <Text style={{ fontWeight: "500", marginBottom: "16px" }}>
          Informasi Terkait Resepsi
        </Text>
        <Text>
          Dengan mempertimbangkan situasi dan kondisi saat ini, kami mohon maaf
          sebesar-besarnya bahwa acara resepsi pernikahan kami,{" "}
          <span style={{ fontWeight: "500" }}>
            sementara diundur sampai situasi kembali normal
          </span>
          . <br />
          <br />
          Namun akad akan tetap dilaksanakan sesuai jadwal.
          <br />
          <br />
          Demikian pemberitahuan dan permohonan maaf ini, semoga kita semua
          selalu dalam lindungan Allah.
        </Text>
        <Button css="margin-bottom: 32px" onClick={closeModal}>
          Tutup
        </Button>
      </Modal>
    </Box>
  )
}

export default Header
