import React from "react"
import { Box, Flex } from "./Grid"
import { Text } from "./Text"
import { theme } from "../util/style/theme"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const CeritaKami = () => {
  const data = useStaticQuery(graphql`
    query {
      photo1: file(relativePath: { eq: "1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      photo2: file(relativePath: { eq: "2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      photo3: file(relativePath: { eq: "3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      photo4: file(relativePath: { eq: "4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Box maxWidth="940px" m="0 auto" px={3} id="ceritakami">
      <Box
        p={[4, 5]}
        style={{ background: theme.colors.white, borderRadius: theme.radii[2] }}
      >
        <Text as="p" mb={3} textAlign="center" style={{ opacity: 0.8 }}>
          Pendahuluan
        </Text>
        <Text
          as="h1"
          fontSize={["2.5rem", "3rem"]}
          mb={[3, 4]}
          textAlign="center"
        >
          Cerita Kami
        </Text>
        <Text
          as="p"
          style={{ opacity: 0.8 }}
          textAlign="center"
          fontSize={["1rem", "1.4rem"]}
        >
          Pertemuan yang tidak disadari adalah hal yang memulai segalanya. Gema
          dan Afnizar pertama kali bertemu di sela-sela perayaan 17 Agustus.
          Berkenalan dan saling sapa yang diawali dengan rasa setiap kali
          bertemu. Semakin beranjak bersama menjadikan diri untuk saling
          percaya. Pertemuan yang tidak disadari memulai cerita untuk perjalanan
          kehidupan bersama.
        </Text>

        <Flex
          justifyContent="space-between"
          flexWrap="wrap"
          mt={[4, 5]}
          css="
          .gatsby-image-wrapper {
            height: 30vh; 
            filter: grayscale(100%);
            transition: all 0.5s ease-in-out;
          }

          @media only screen and (max-width: 576px) {
            .gatsby-image-wrapper {
              height: 20vh;
            }
          }

          .gatsby-image-wrapper:hover {
            filter: grayscale(0%);
            transition: all 0.5s ease-in-out;
          }
        "
        >
          <Img
            css="max-height: 100%;
          margin-bottom: 20px;
          min-width: 39%; 
          @media only screen and (max-width: 576px) {
            margin-bottom: 6px;
          }"
            fluid={data.photo1.childImageSharp.fluid}
          />
          <Img
            css="max-height: 100%;
          margin-bottom: 20px;
          min-width: 59%;
          @media only screen and (max-width: 576px) {
            margin-bottom: 6px;

          }"
            fluid={data.photo2.childImageSharp.fluid}
          />
          <Img
            css="max-height: 100%;
          min-width: 59%;
          @media only screen and (max-width: 576px) {
            margin-bottom: 6px;

          }"
            fluid={data.photo3.childImageSharp.fluid}
          />
          <Img
            css="max-height: 100%;
          min-width: 39%;
          @media only screen and (max-width: 576px) {
            margin-bottom: 6px;

          }"
            fluid={data.photo4.childImageSharp.fluid}
          />
        </Flex>
      </Box>
    </Box>
  )
}

export default CeritaKami
