import React from "react"
import { Box } from "./Grid"
import { Text } from "./Text"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { theme } from "../util/style/theme"
import ImageMasonry from "react-image-masonry"

const Gallery = () => {
  let images = []
  for (let i = 1; i < 13; i++) {
    images.push("/images/gallery/gallery-" + i + ".jpg")
  }

  return (
    <ImageMasonry
      css="
        img {
            filter: grayscale(100%);
            transition: all 0.5s ease-in-out;
        }; 
        img:hover { filter: grayscale(0%);
            transition: all 0.5s ease-in-out;
        }"
      imageUrls={images}
      numCols={4}
      containerWidth={"100%"}
    />
  )
}

export default Gallery
