import React from "react"
import { Box } from "./Grid"
import { Text } from "./Text"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { theme } from "../util/style/theme"

const Link = styled("a")`
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    text-decoration: none;
  }
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      header: file(relativePath: { eq: "stamp-afnizar-gema.png" }) {
        childImageSharp {
          fixed(width: 129) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Box maxWidth="940px" m="0 auto" px={3}>
      <Box
        mt={[5, "152px"]}
        mb={[5, "119px"]}
        style={{
          textAlign: "center",
        }}
      >
        <Text as="h1" fontSize={["3rem", "4rem"]} mb={[3, 4]}>
          Terima kasih
        </Text>
        <Text as="p" fontSize={["1rem", "1.4rem"]} mb={4}>
          Design & code oleh{" "}
          <Link target="blank" href="https://afnizarnur.com">
            mempelai pria
          </Link>
          . Didukung oleh{" "}
          <Link target="blank" href="https://www.linkedin.com/in/gemaabr">
            mempelai wanita
          </Link>
          . Cakep~ <br /> Dibangun dengan React, Gatsby, dan Netlify.
        </Text>
        <Img
          style={{ display: "block", margin: "0 auto", marginTop: "62px" }}
          css="
            @keyframes rotate {
                0% {
                transform: rotate(0deg)
                }
            
                100% {
                transform: rotate(360deg)
                }
            }
            
            animation: rotate 10s infinite linear;
        "
          fixed={data.header.childImageSharp.fixed}
        />
      </Box>
    </Box>
  )
}

export default Footer
