import React from "react"
import { Flex, Box } from "./Grid"
import { Text } from "./Text"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const NavLink = styled(AnchorLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  opacity: 0.8;

  &:hover {
    text-decoration: underline;
    opacity: 1;
  }
`

const Navigation = ({ ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      wave: file(relativePath: { eq: "hand-wave.png" }) {
        childImageSharp {
          fixed(width: 18) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <div
      style={{
        background:
          "linear-gradient(to bottom, rgba(248,242,235,1) 41%, rgba(255,255,255,0) 100%)",
      }}
      css="
        position: fixed; 
        margin: auto; 
        top: 0; 
        left:0; 
        width: 100%; 
        z-index: 10;
        a, p { 
            white-space: nowrap; 
        }
        animation: navFadeInTop 1.8s 1.8s cubic-bezier(0.19, 1, 0.22, 1) backwards;
    "
    >
      <Flex
        css="
            max-width: 940px; 
            @media only screen and (max-width: 576px) {
                overflow-x: scroll;
            }
        "
        py={3}
        px={3}
        alignItems="center"
        justifyContent="space-between"
        mx="auto"
      >
        <Flex>
          <Text css="display: inline-block">
            Untuk{" "}
            <span
              onClick={
                props.name
                  ? ""
                  : function() {
                      alert("Iya kamu~")
                    }
              }
              css="text-decoration: underline"
            >
              {props.name ? props.name : "kamu"}
            </span>{" "}
          </Text>
          <span css="margin-right: 48px; display: inline-block; margin-left: 6px; position: relative; top: 3px; animation: wave 2s infinite linear;">
            <Img fixed={data.wave.childImageSharp.fixed} />
          </span>
        </Flex>
        <Box>
          <NavLink href="#ceritakami" css="margin-right: 30px">
            Cerita Kami
          </NavLink>
          <NavLink href="#acara" css="margin-right: 30px">
            Acara
          </NavLink>
        </Box>
      </Flex>
    </div>
  )
}

export default Navigation
